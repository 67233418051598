<template>
  <ul class="tabs">
    <li
        class="tabs-item"
        v-for="(item, index) in info"
        :key="index"
        :class="{ active: item.name === $route.name }"
        @click="$router.push(item.url)">
      <div class="tab-text">
        {{ item.label }}
      </div>

    </li>
  </ul>
</template>

<script>
export default {
  name: "HiTabLayout",

  props: {
    info: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
ul{
  display: flex;
  padding: 0;
  margin: 3px 0 20px;
  li{
    list-style: none;
    padding: 0 23px;
    color: #333333;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular,system-ui;
    .tab-text{
      padding: 0 0 6px 0;
    }
    &.active{
      .tab-text{
        color: #ff004d;
        border-bottom: #ff004d 2px solid;
      }
    }
  }
}
</style>
