<template>
  <div>
    <hi-tab-layout :info="info"></hi-tab-layout>
    <div style="background-color: white;padding: 25px 40px">
      <router-view></router-view>
    </div>
  </div>

</template>

<script>
import HiTabLayout from "@/components/HiTabLayout"

export default {
  name: "Company",

  components: {
    HiTabLayout
  },

  data(){
    return {
      info: [
        {
          label: '机构信息',
          name: 'CompanyInfo',
          url: 'info'
        },
        {
          label: '支付设置',
          name: 'PaymentSettings',
          url: 'payment-settings'
        },
        {
          label: '权限设置',
          name: 'CompanyPermission',
          url: 'permission'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
